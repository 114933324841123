@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 100 900;
	font-display: optional;
	src: url(/fonts/inter-var.ttf) format('truetype');
}

:root {
	--h-content-area: calc(100vh - theme('height.14'));
}

.min-height-mobile {
	min-height: calc(100vh - theme('height.4'));
}

/* focus effects */

.focus-brand-with-border {
	@apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-brand-blue focus:border-brand-blue dark:focus:ring-brand-blue dark:focus:border-brand-blue;
}

.focus-brand-without-border {
	@apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-brand-blue;
}

/* hover effects */

.hover-brand {
	@apply hover:text-brand-blue dark:hover:text-brand-blue;
}

/* background colors */

.bg-brand-primary {
	@apply bg-gray-200 dark:bg-gray-900;
}

.bg-brand-secondary {
	@apply bg-gray-50 dark:bg-gray-800;
}

.bg-brand-gradient {
	@apply bg-gradient-to-r from-brand-blue to-brand-lightBlue;
}

/* text colors */

.text-brand-primary {
	@apply text-black dark:text-gray-100;
}

.text-brand-secondary {
	@apply text-gray-500 dark:text-gray-400;
}

.text-brand-gradient {
	@apply text-transparent bg-clip-text bg-gradient-to-r from-brand-blue to-brand-lightBlue;
}

/* border colors */

.border-brand {
	@apply border-gray-500 dark:border-gray-700;
}

/* placeholder colors */

.placeholder-brand {
	@apply placeholder-gray-500 dark:placeholder-gray-400;
}

/* button styles */

.btn-primary {
	@apply text-white transition duration-150 ease-in-out border-2 rounded-md border-brand-blue bg-brand-blue hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue dark:focus:ring-brand-blue dark:focus:ring-offset-brand-lightGrey;
}

.btn-secondary {
	@apply transition duration-150 ease-in-out bg-transparent border-2 rounded-md dark:text-brand-steelLight dark:border-brand-steelLight dark:hover:bg-brand-steelLight text-brand-steelDark border-brand-steelDark hover:bg-brand-steelDark hover:text-white dark:hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue dark:focus:ring-brand-blue dark:focus:ring-offset-brand-lightGrey;
}

/* link styles */

.link-brand {
	@apply transition duration-150 ease-in-out rounded-md text-brand-blue hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand-blue dark:focus:ring-brand-blue dark:focus:ring-offset-brand-lightGrey;
}

/* custom scrollbar */

::-webkit-scrollbar {
	width: 16px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #9ca3af;
	border-radius: 20px;
	border: 4px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #93c5fd;
}
